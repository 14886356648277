<template>
  <div id="app">
    <!-- 导航栏 -->
    <div id="app">
      <Ztl />
    </div>


    <!-- 图片轮播 -->
    <div>
      <el-carousel height="400px">
        <el-carousel-item v-for="(item, index) in images" :key="index">
          <img :src="item.src" alt="Image" style="width: 100%; height: 100%;">
        </el-carousel-item>
      </el-carousel>
    </div>


    <!-- 产品中心 -->
    <div class="zzzs-section">
      <h2>
        <strong class="hangyejiejuefangan">
          <span class="title-text">产品</span>
          <span class="highlighted-text">中心</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- PRODUCT CENTER ----------</span>
      </h2>
    </div>
    <div class="content-area">
      <router-link
          v-for="(feature, index) in features1"
          :key="index"
          :to="feature.route"
          class="feature-block"
          target="_blank"
      >
        <!--- 这里上面的target="_blank"是为了在新标签页打开，可以打开外部链接，如果不需要可以去掉 -->
        <img :src="feature.image" :alt="feature.title" class="feature-image">
        <div class="feature-title">{{ feature.title }}</div>
        <div class="feature-hover-text" v-html="feature.description"></div>
      </router-link>
    </div>

    <!-- 执照证书图片 -->
    <div class="zzzs-section">
      <h2>
        <strong class="title-part">
          <span class="title-text">资质</span>
          <span class="highlighted-text">证书</span>
        </strong>
      </h2>
      <h2>
          <span class="highlighted-text1">---------- QUALIFICATION CERTIFICATE ----------</span>
      </h2>
      <h2>
        <span class="highlighted-text2">合作案例 知识产权证书</span>
      </h2>
      <div class="zzzs-container">
      <el-image
          v-for="(image, index) in zzzsImages"
          :key="index"
          :src="image.src"
          fit="contain" style="max-width: 300px; max-height: 500px; margin: 10px;"
      ></el-image>
      </div>

      <h2>
        <span class="highlighted-text2">知识产权证书</span>
      </h2>
      <div class="zzzs-container">
        <el-image
            v-for="(image, index) in zzzsImages1"
            :key="index"
            :src="image.src"
            fit="contain" style="max-width: 300px; max-height: 500px; margin: 10px;"
        ></el-image>
      </div>
      </div>

    <!-- 行业解决方案 -->
    <div class="zzzs-section">
      <h2>
        <strong class="hangyejiejuefangan">
          <span class="title-text">行业</span>
          <span class="highlighted-text">解决方案</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- INDUSTRY SOLUTIONS ----------</span>
      </h2>
    </div>

    <!-- 行业解决方案 -->
    <div class="content-area">
      <router-link
          v-for="(feature, index) in features"
          :key="index"
          :to="feature.route"
          class="feature-block1"
          target="_blank"
      >
        <!--- 这里上面的target="_blank"是为了在新标签页打开，可以打开外部链接，如果不需要可以去掉 -->
        <img :src="feature.image" :alt="feature.title" class="feature-image">
        <div class="feature-title">{{ feature.title }}</div>
        <div class="feature-hover-text" v-html="feature.description"></div>
      </router-link>
    </div>

    <!-- 企业优势 -->
    <div class="zzzs-section">
      <h2>
        <strong class="hangyejiejuefangan">
          <span class="title-text">企业</span>
          <span class="highlighted-text">优势</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- ENTERPRISE ADVANTAGE ----------</span>
      </h2>
    </div>

    <!-- 企业优势 -->
    <div class="youshi" :style="{ backgroundImage: `url(${backgroundImage})` }">
      <ul class="youshi-list">
        <li class="youshi-item" v-for="(advantage, index) in formattedAdvantages" :key="index">
          <div class="item-content">
            <img :src="advantage.image" :alt="advantage.title">
            <p>{{ advantage.title }}</p>
            <span v-html="advantage.description"></span>
          </div>
        </li>
      </ul>
    </div>

    <!-- 合作伙伴 -->
    <div class="zzzs-section">
      <h2>
        <strong class="hangyejiejuefangan">
          <span class="title-text">合作</span>
          <span class="highlighted-text">伙伴</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- COOPERATIVE PARTNER ----------</span>
      </h2>
    </div>

    <!-- 新增的合作方图片部分 -->
    <div class="partner-images">
      <img v-for="(partner, index) in partners" :key="index" :src="partner.src" alt="Partner Image">
    </div>
    <div id="app">
      <!-- 页面的其他内容 -->
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Ztl from '@/components/Ztl.vue';


export default {

  components: {
    Footer,
    Ztl,

  },
  name: 'Guanwang',
  computed: {
    formattedAdvantages() {
      return this.advantages.map(advantage => ({
        ...advantage,
        description: advantage.description.replace(/\n/g, '<br>')
      }));
    }
  },
  data() {
    return {
      images: [
        { src: require('../assets/SYlbt/lbt1.jpg') },
        { src: require('../assets/SYlbt/lbt2.jpg') },
        { src: require('../assets/SYlbt/lbt3.jpg') },
      ],
      features1: [


        {
          title: '定位系统', // 将显示在图片下方的标题
          image: require('@/assets/SYcpzx/dwxt.jpg'), // 图片路径，确保路径正确
          description: 'UWB定位系统', // 鼠标悬停时显示的详细描述
          route: '/uwbdingweixitong', // 路由路径，用于链接跳转
        },
        {
          title: '定位软件', // 将显示在图片下方的标题
          image: require('@/assets/uwbxitong/dwxt1.jpg'), // 图片路径，确保路径正确
          description: '定位软件', // 鼠标悬停时显示的详细描述
          route: '/uwbdingweiruanjian', // 路由路径，用于链接跳转
        },
        {
          title: '定位基站', // 将显示在图片下方的标题
          image: require('@/assets/uwbjizhan/gyx.jpg'), // 图片路径，确保路径正确
          description: 'UWB定位基站', // 鼠标悬停时显示的详细描述
          route: '/uwbdingweijizhan', // 路由路径，用于链接跳转
        },
        {
          title: '定位微标签', // 将显示在图片下方的标题
          image: require('@/assets/SYcpzx/dwbq.jpg'), // 图片路径，确保路径正确
          description: '不同场景定位微标签', // 鼠标悬停时显示的详细描述
          route: '/uwbdingweibiaoqian', // 路由路径，用于链接跳转
        },
        {
          title: '自定义开发平台', // 将显示在图片下方的标题
          image: require('@/assets/uwbkfpt/kfpt1.jpg'), // 图片路径，确保路径正确
          description: '行业最易集成的系统', // 鼠标悬停时显示的详细描述
          route: '/uwbdingweizdykfpt', // 路由路径，用于链接跳转
        },
        {
          title: '防爆产品', // 将显示在图片下方的标题
          image: require('@/assets/SYcpzx/fbcp.jpg'), // 图片路径，确保路径正确
          description: 'UWB防爆产品', // 鼠标悬停时显示的详细描述
          route: '/fangbaochanpin', // 路由路径，用于链接跳转
        },
      ],
      zzzsImages: [
        { src: require('../assets/SYzzzs/zzzs1.jpg') },
        { src: require('../assets/SYzzzs/zzzs2.jpg') },
        { src: require('../assets/SYzzzs/zzzs3.jpg') },
        { src: require('../assets/SYzzzs/zzzs4.jpg') },
        // 添加更多图片
      ],
      zzzsImages1: [
        { src: require('../assets/SYzzzs/kyzs1.jpg') },
        { src: require('../assets/SYzzzs/kyzs2.jpg') },
        { src: require('../assets/SYzzzs/kyzs3.jpg') },
        { src: require('../assets/SYzzzs/kyzs4.jpg') },
        // 添加更多图片
      ],
      features: [
        {
          title: '智 能 制 造', // 将显示在图片下方的标题
          image: require('@/assets/SYhyjjfa/znzz.jpg'), // 图片路径，确保路径正确
          description: '10cm高精度定位，助力升级煤矿安全避险六大系统。保障人员安全的同时，提高生产调度指挥能力。', // 鼠标悬停时显示的详细描述
          route: '/zhinengzhizao', // 路由路径，用于链接跳转
        },
        {
          title: '企 业 内 仓 储', // 将显示在图片下方的标题
          image: require('@/assets/SYhyjjfa/qyyncc.jpg'), // 图片路径，确保路径正确
          description: '10cm高精度定位，助力升级煤矿安全避险六大系统。保障人员安全的同时，提高生产调度指挥能力。', // 鼠标悬停时显示的详细描述
          route: '/qiyeneicangchu', // 路由路径，用于链接跳转
        },
        {
          title: '大 型 场 所', // 将显示在图片下方的标题
          image: require('@/assets/SYhyjjfa/dxcs.jpg'), // 图片路径，确保路径正确
          description: '10cm高精度定位，助力升级煤矿安全避险六大系统。保障人员安全的同时，提高生产调度指挥能力。', // 鼠标悬停时显示的详细描述
          route: '/daxingchangsuo', // 路由路径，用于链接跳转
        },
        {
          title: '数 据 中 心', // 将显示在图片下方的标题
          image: require('@/assets/SYhyjjfa/sjzx.jpg'), // 图片路径，确保路径正确
          description: '无延时地将巡检人员位置信息显示在机房控制中心，进行安全区域管控、人员在岗监控等。精度达到10厘米级，精确管控以优化流程、合理调度安排、提高数据机房的巡检效率。', // 鼠标悬停时显示的详细描述
          route: '/shujuzhongxin', // 路由路径，用于链接跳转
        },
      ],

      backgroundImage: require('@/assets/SYqyys/shanghai.jpg'), // 整体背景图片路径
      advantages: [
        {
          title: '更高精度',
          description: '10cm高精度定位\n' +
              '无线定位专利技术，远超传统定位方式',
          image: require('@/assets/SYqyys/ggjd.png'),
        },
        {
          title: '更易集成',
          description: 'UWB+自定义开发方便集成商二次开发\n' +
              '行业最易集成的定位系统',
          image: require('@/assets/SYqyys/gyjc.png'),
        },
        // 添加更多板块...
        {
          title: '更大容量',
          description: '25000+定位容量\n' +
              '可容纳标签数量无限扩展',
          image: require('@/assets/SYqyys/gdrl.png'),
        },
        {
          title: '更低成本',
          description: '从硬件到方案设计\n' +
              '再到安装运维，为您节省50%',
          image: require('@/assets/SYqyys/gdcb.png'),
        },
        {
          title: '更多案例',
          description: '全国地区、2000+落地项目\n' +
              '市场占有率遥遥领先\n' +
              '覆盖全行业应用场景',
          image: require('@/assets/SYqyys/gdal.png'),
        },
        {
          title: '更优服务',
          description: '华为LTC、IPD、ITR服务和管理流程\n' +
              '服务质量行业领先',
          image: require('@/assets/SYqyys/gyfw.png'),
        },
      ],
      partners: [
        { src: require('@/assets/SYhzhb/gjdw.jpg') },
        { src: require('@/assets/SYhzhb/bw.jpg') },
        { src: require('@/assets/SYhzhb/fyjt.jpg') },
        { src: require('@/assets/SYhzhb/gxxwl.jpg') },
        { src: require('@/assets/SYhzhb/shzlzx.jpg') },
        { src: require('@/assets/SYhzhb/zgdt.jpg') },
        { src: require('@/assets/SYhzhb/zghd.jpg') },
        { src: require('@/assets/SYhzhb/zgsy.jpg') },
        { src: require('@/assets/SYhzhb/zgtj.jpg') },
        { src: require('@/assets/SYhzhb/zjky.jpg') },

        // 更多图片
      ],
    };
  },
};
</script>

<style scoped>




.zzzs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.zzzs-section {
  text-align: center;
}
.zzzs-section h2 {
  margin-bottom: 10px;
}
.highlighted-text {
  color: #4a83fd;
  font-size: 35px;
}
.title-text {
  color: #f64a4a;
  font-size: 35px;
}
.highlighted-text1 {
  color: #4a83fd;
  font-size: 20px;
}

.content-area {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中，仅在单行有效 */
  width: 100%; /* 或者指定一个具体的宽度 */
  max-width: 1237px; /* 确保不会超出屏幕宽度 */
  margin: 0 auto; /* 水平居中 */
}


.feature-block {
  display: flex;
  flex-direction: column; /* 垂直堆叠元素 */
  align-items: center; /* 水平居中对齐 */
  justify-content: center; /* 垂直居中对齐，可选 */
  flex: 0 0 calc(25% - 20px);
  margin: 20px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center; /* 文字居中对齐 */
  position: relative; /* 添加这一行，使.feature-hover-text相对于.feature-block定位 */
}
.feature-block1 {
  display: flex;
  flex-direction: column; /* 垂直堆叠元素 */
  align-items: center; /* 水平居中对齐 */
  justify-content: center; /* 垂直居中对齐，可选 */
  flex: 0 0 calc(25% - 40px);
  margin: 20px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center; /* 文字居中对齐 */
  position: relative; /* 添加这一行，使.feature-hover-text相对于.feature-block定位 */
}

.feature-image {
  width: 100%; /* 图片宽度与容器相同 */
  height: auto;
}
.feature-hover-text {
  position: absolute;
  bottom: 0; /* 改为从底部开始 */
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgb(6, 42, 222); /* 半透明背景色 */
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.feature-block:hover .feature-hover-text {
  visibility: visible;
  opacity: 1;
}
.feature-title {
  font-size: 1.5em;
  font-weight: bold; /* 增加字体粗细 */
  color: #000000; /* 改变字体颜色 */
  text-align: center;
  margin-top: 10px;
}
.youshi {
  display: flex;
  justify-content: center;
  align-items: stretch; /* 改为stretch，使.youshi-item占据.youshi的高度 */
  position: relative;
  height: fit-content; /* 自动适应内容高度 */
  background-size: cover;
  background-position: center;
}

.youshi-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.youshi-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative; /* 添加相对定位，确保内容在背景之上 */
  z-index: 1; /* 设置较高的z-index，确保内容在背景之上 */
  width: calc(33.33% - 20px); /* 计算每列的宽度，留出间距 */
  margin-bottom: 20px;
  padding: 20px;
  transition: all 0.3s ease; /* 添加过渡效果，使所有变化平滑 */
}

.youshi-item img {
  max-width: 100%;
  height: auto;
}

.youshi-item p {
  font-size: 25px; /* 增加字体大小 */
  margin: 10px 0;
  color: white; /* 设置title文字颜色为白色 */
}
.youshi-item span {
  color: white; /* 设置title和description文字颜色为白色 */
}
.youshi-item:hover p {
  color: #f62222; /* 鼠标悬停时的文字颜色 */
}
.partner-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  padding: 0 15%;
  transition: all 0.3s ease; /* 添加过渡效果，使变换平滑 */
}

.partner-images img {
  flex-basis: calc(18% - 10px);
  max-width: calc(18% - 10px);
  height: auto;
  transition: transform 0.3s ease; /* 添加过渡效果，使变换平滑 */
}

.partner-images img:hover {
  transform: translateY(-10px); /* 鼠标悬停时图片向上移动5px */
}

/* 当屏幕宽度小于或等于768px时，减少左右空白区域 */
@media (max-width: 768px) {
  .partner-images {
    padding: 0 5%;
  }
}
/* 新增备案号样式 */
.small {
  font-size: 0.8em;
  color: #969d96;
}

</style>
